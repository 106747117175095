<template>
  <div class="m-2" v-if="configuration">
    <h6 class="mb-3">Данные вашего магазина на маркетплейсе для формирования прайса</h6>
    <hr/>
    <div class="row py-1">
      <div class="col-4 font-weight-bold">Название компании</div>
      <div class="col-8">{{ configuration.company_name }}</div>
    </div>
    <hr/>
    <div class="row py-1">
      <div class="col-4 font-weight-bold">Идентификатор клиента</div>
      <div class="col-8">{{ configuration.external_service_indentificator }}</div>
    </div>
    <hr/>
    <div class="row py-1">
      <div class="col-4 font-weight-bold">Домен вашего сайта для прайса</div>
      <div class="col-8">{{ configuration.domain }}</div>
    </div>
    <hr/>
    <div class="row py-1">
      <div class="col-4">Ссылка на прайс</div>
      <div class="col-8">
        https://data.brandstock.online/xml/{{ this.prefix }}/{{ configuration.uid }}.xml
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExternalServiceConfiguration',
  props: ['externalServiceId', 'prefix'],
  data() {
    return {
      breadcrumbs: [],
      configuration: null,
    };
  },
  watch: {
    externalServiceId: {
      async handler() {
        await this.getConfiguration();
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getConfiguration();
  },
  methods: {
    async getConfiguration() {
      this.configuration = (await this.$api.external.configuration.info(this.externalServiceId)).data;
    },
  },
};
</script>

<style scoped>
</style>
